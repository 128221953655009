@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.gallery-main-container {
    height: 100%;
    width: 100%;
}

.gallery-main-content-container > .gallery-sub-headers-container {
    padding: 2rem 2rem 0rem 7rem;
}

.gallery-content-head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
    padding-top: 9%;
}

.head-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    color: #000000;
    text-align: center;
}

.head-title > span {
    color: #F4D68F;
}

.carousel-head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 35.6917px;
    line-height: 23px;
    margin-top: 2rem;
    text-align: left;
    color: #000000;
    margin-left: 7rem;
}

.carousel-head-main {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 35.6917px;
    line-height: 23px;
    text-align: left;
    color: #F4D68F;
}

.gallery-carousel {
    width: 100%;
    height: 40vh;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.normal {
    display: block;
}

.gallery-carousel.mobile {
    display: none;
}

.gallery-carousel.tablet {
    display: none;
}

.carousel .carousel-control-prev {
    width: 4rem;
}

.carousel .carousel-control-next {
    width: 4rem;
}

.swiper-wrapper {
    margin-left: 5%;
}

.gallery-carousel .swiper-wrapper {
    height: 100%;
    width: 90%;
    display: block;
}

.gallery-carousel .carousel-inner .carousel-item {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 2rem 0;
}

.image-div-container {
    height: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
}

.image-div-container > img {
    height: 100%;
    width: auto;
}

@media (max-width:964px) {    
    .gallery-main-container {
        padding-top: 20%;
    }

    .gallery-main-content-container > .gallery-sub-headers-container {
        padding: 2rem 1rem 0 0;
        flex-direction: row;
    }

    .normal {
        display: none;
    }

    .gallery-carousel.tablet {
        display: block;
    }

    .gallery-carousel.mobile {
        display: none;
    }

    .swiper-wrapper {
        margin-left: 0;
    }

    .carousel-head {
        margin-left: 2rem;
    }

    .carousel-head-main {
        margin-left: 2rem;
    }

    .gallery-carousel {
        height: 30vh;
    }

    .gallery-content-head {
        padding-top: 0;
    }
}

@media (max-width:432px) {
    .gallery-carousel {
        height: 45vh;
    }

    .gallery-main-container {
        padding-top: 20%;
    }

    .gallery-sub-headers-container > .view-all-container {
        margin-right: 0;
    }

    .gallery-content-head {
        margin: 0;
        padding-top: 9%;
    }

    .head-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 60px;
        color: #000000;
        text-align: center;
    }

    .carousel-head {
        font-size: 30px;
        line-height: 35px;
        margin-top: 2rem;
    }

    .carousel-head-main {
        font-size: 30px;
    }

    .normal {
        display: none;
    }

    .gallery-carousel.tablet {
        display: none;
    }

    .gallery-carousel.mobile {
        display: block;
    }

    .swiper-wrapper {
        gap: 0;
        margin-left: 0;
    }

    .swiper-slide .image-div-container {
        padding: 2rem 2rem;
    }
}