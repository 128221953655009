@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.contactUs-container {
    background: url("../../images/testimonies_banner.png");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: 50% 100%;
    background-position: right;
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
}

.contact-form-container {
    margin-left: 15%;
    margin-top: 5%;
}

.contact-form-container > span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 40.4921px;

    color: #000000;
}

.contact-main-form {
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
    padding: 1.5rem 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.contact-main-form > div:nth-child(1),
.contact-main-form > div:nth-child(2),
.contact-main-form > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #F1CD76;
}

.contact-main-form > div > input {
    border: 2px solid #C9C9C9;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18.6595px;
    line-height: 30px;
    color: #888888;
}

.contact-main-form > div > input:focus {
    border: 2px solid #F1CD76;
    box-shadow: 0 0 0 0.1rem #F1CD76;
}

span.message-sent {
    height: 2rem;
    padding: 0 10px;
    border-radius: 10px;
    display: none;
    align-items: center;
    justify-content: center;
    color: green;
    border: 2px solid green;
}

.contact-button {
    background-color: #F1CD76;
    box-shadow: 0px 5.04666px 27.3684px rgba(0, 0, 0, 0.25);
    border-radius: 10.2456px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    margin-top: 0.5rem;
    color: white;
    outline: none;
    border: none;
    width: 90%;
    padding: 10px;
    transition: all 0.2s ease-in-out;
}

.call-div {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.call-div .span-2 > a{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30.8351px;
    line-height: 45px;
    text-decoration: none;
    color: black;
}

.form-text {
    text-align: left;
    margin-left: 10px;
}

.form-text-span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18.0333px;
    line-height: 20px;

    color: #B6B6B6;
}

@media (max-width:964px) {
    .contactUs-container {
        background-size: 100% 100%;
        align-items: flex-start;
    }

    .contact-form-container {
        margin-left: 25%;
        margin-top: 20%;
    }

    .contact-button 
    {
        width: 100%;
    }
}

@media (max-width:432px) {
    .contactUs-container {
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-size: 100% 100%;
        background-position: bottom;
    }

    .contact-form-container {
        margin-left: 0;
    }

    .contact-main-form > input {
        font-size: 16.6595px;
        line-height: 20px;
    
        color: #888888;
    }

    .contact-main-form {
        gap: 0.5rem;
        padding: 2rem 1rem;
    }

    .call-div .span-2 > a {
        font-size: 28px;
    }

    .form-text-span {
        font-size: 14px;
    }
}