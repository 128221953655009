@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.about-container {
    background: white;
    height: 100vh;
    width: 100%;
}

.about-inner-container {
    background: url("../../images/about-page-background.webp");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.about-container-head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    text-transform: uppercase;
    color: black;
    text-shadow: 7px 4px 4px rgba(0, 0, 0, 0.25);
    border-bottom: 5px solid #EECC7C;
}

.about-content {
    width: 60%;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: 'Inter';
    font-weight: 600;
    text-align: justify;
    text-transform: uppercase;
    color: #000000;
    border-radius: 20px;
}

.about-content-main > span {
    color: #EECC7C;
    margin-right: 0.5rem;
    font-size: 20px;
}

@media (max-width:920px) {
    .about-container {
        background-size: 100% 100%;
    }

    .about-inner-container {
        background-size: 250% 80%;
        background-position: bottom right;
        justify-content: flex-start;
        margin-top: 15%;
    }

    .about-content {
        width: 80%;
    }
}

@media (max-width:432px) {
    .about-container {
        height: 120vh;
    }

    .about-inner-container {
        background-size: 300% 60%;
        margin-top: 25%;
        height: 100%;
    }

    .about-content {
        width: 90%;
        font-size: 12px;
        margin-bottom: -5%;
        padding: 25px;
    }

    .about-container-head {
        font-size: 34px;
    }
    
    .about-content-main > span {
        font-size: 18px;
    }
}

@media (max-width:342px) {
    .about-container {
        height: 100%;
    }

    .about-inner-container {
        gap: 2rem;
        height: 100%;
        padding: 50px 0;
    }

    .about-content {
        width: 90%;
        font-size: 12px;
        margin-bottom: -5%;
        padding: 25px;
    }

    .about-container-head {
        font-size: 34px;
        margin-top: 25%;
    }
    
    .about-content-main > span {
        font-size: 18px;
    }
}