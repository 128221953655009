@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.commercial-container {
    background: url("../../images/commercial_page_banner.webp");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.commercial-inner-container {
    height: 80%;
    width: 80%;
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.5rem;
    margin-bottom: 2rem;
    border-radius: 20px;
}

.commercial-inner-container > div {
    background: url("../../images/commercial-page-image.webp");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.commercial-container-head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    width: 100%;
    text-transform: uppercase;
    color: black;
    text-shadow: 7px 4px 4px rgba(0, 0, 0, 0.25);
}

.commercial-container-head > span {
    border-bottom: 4px solid #EECC7C;
}

.commercial-container-content {
    width: 60%;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem 2rem 2rem;
    border-radius: 1rem;
    gap: 0.2rem;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 10%;
}

.commercial-content-head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    text-transform: uppercase;
    color: #000000;
    text-align: left;
    margin-left: -10%;
}

.commercial-content-text {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    text-shadow: 7px 4px 4px rgba(0, 0, 0, 0.25);
    width: 70%;
}

.commercial-content-list {
    width: 80%;
    color: #EECC7C;
}

.commercial-content-list > ul {
    text-align: left;
    margin-left: 1rem;
    color: #EECC7C;
    list-style-type: square;
    font-size: 22px;
}

.commercial-content-list > ul > li > span {
    text-align: left;
    margin-left: 1rem;
    font-size: 18px;
    color: #000000;
}

.commercial-content-button {
    background-color: #EECC7C;
    border-radius: 10px;
    width: 25%;
    padding: 5px 15px;
    transition: all 0.2s ease-in-out;
}

.commercial-content-button > a {
    color: black;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}

.commercial-content-button:hover {
    background-color: #d2b46c;
}

.commercial-content-button > a:hover {
    color: black;
}

@media (max-width:920px) {
    .commercial-container {
        background-size: 100% 100%;
    }

    .commercial-inner-container > div {
        background-size: 100% 50%;
        align-items: flex-start;
        padding-left: 1rem;
    }

    .commercial-container-head {
        font-size: 32px;
    }

    .commercial-content-text {
        width: 100%;
    }

    .commercial-content-list {
        width: 100%;
        margin-left: 0;
    }

    .commercial-content-list > ul {
        margin-left: 0;
    }

    .commercial-content-button {
        width: 60%;
    }

}

@media (max-width:432px) {
    .commercial-container {
        height: 100%;
        padding-top: 25%;
    }

    .commercial-inner-container {
        height: 85vh;
    }

    .commercial-container-head {
        font-size: 22px;
    }

    .commercial-container-content {
        width: 90%;
    }

    .commercial-inner-container > div {
        background-size: 150% 30%;
        background-position: bottom right;
        align-items: flex-start;
        padding-left: 1rem;
        padding-top: 2rem;
        justify-content: flex-start;
    }

    .commercial-content-head {
        font-size: 20px;
    }
    
    .commercial-content-text {
        font-size: 12px;
    }
    
    .commercial-content-list {
        width: 100%;
    }
    
    .commercial-content-list > ul {
        font-size: 18px;
        margin-left: 0;
    }
    
    .commercial-content-list > ul > li > span {
        font-size: 14px;
        margin-left: 0;
    }
    
    .commercial-content-button {
        width: 80%;
    }
    
    .commercial-content-button > a {
        font-size: 16px;
    }
}

@media (max-height: 700px) {
    .commercial-inner-container {
        height: 110vh;
    }
}