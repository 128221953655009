@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.services-container {
    background: url("../../images/commercial_page_banner.webp");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.services-inner-container {
    background: url("../../images/services-page-image.webp");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 80%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 2rem;
    border-radius: 20px;
}

.services-container-head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    text-transform: uppercase;
    color: black;
    text-shadow: 7px 4px 4px rgba(0, 0, 0, 0.25);
    border-bottom: 4px solid #EECC7C;
    margin-top: 1rem;
}

.services-container-sub-head {
    color: black;
    padding: 5px 20px;
    font-size: 18px;
    font-weight: 600;
    width: 60%;
}

.services-icons-container {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 1.5rem;
}

.services-icon-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 20%;
    border-radius: 20px;
    transition: 0.2s all ease-in-out;
    cursor: pointer;
}

.services-icon-container:hover {
    transform: scale(1.1);
}

.services-icon-container > img {
    height: 100%;
    width: auto;
}

.services-icon-name {
    border-bottom: 3px solid#EECC7C;
    padding: 2px 10px;
    font-size: 14px;
    font-weight: 700;
}

@media (max-width:920px) {
    .services-container {
        background-size: 100% 100%;
        align-items: center;
        justify-content: center;
    }

    .services-inner-container {
        height: 70%;
        justify-content: center;
        gap: 2rem;
    }

    .services-container-sub-head {
        width: 100%;
    }

    .services-icons-container {
        width: 100%;
    }
}

@media (max-width:432px) {
    .services-container {
        height: 100%;
        padding-top: 25%;
    }

    .services-inner-container {
        height: 100%;
        gap: 1rem;
    }

    .services-icons-container {
        width: 90%;
        padding: 0 2% 5% 2%;
        height: 65%;
    }

    .services-icon-container {
        height: 150px;
        width: 45%;
    }

    .services-icon-name {
        font-size: 12px;
    }

    .services-container-sub-head {
        font-size: 14px;
    }
}

@media (max-width:342px) {
    .services-container {
        height: 120vh;
        background-size: cover;
    }

    .services-inner-container {
        height: 100%;
        gap: 1rem;
        background-size: cover;
    }

    .services-container-head {
        font-size: 36px;
        margin-top: 35%;
    }

    .services-icons-container {
        width: 95%;
        padding: 0 2% 5% 2%;
        height: 50%;
    }

    .services-icon-container {
        height: auto;
        width: 45%;
        margin-top: 0.5rem;
    }

    .services-icon-container > img {
        height: auto;
        width: 30%;
    }

    .services-icon-name {
        font-size: 12px;
    }

    .services-container-sub-head {
        font-size: 18px;
    }
}